import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useAppSelector, useBreakPoint } from 'hooks';
import { authSelector } from 'store/slices/auth';
import { recordsSelector } from 'store/slices/records';
import { rolesValuesMap } from 'types/player';

import { Card } from 'components/Card';
import { EmtyScreen } from 'components/EmtyScreen';

interface IClansStreamListProps {
  id: string;
  clan: string;
  place: string;
}

export const ClansStreamList: FC<IClansStreamListProps> = ({
  id,
  clan,
  place,
}) => {
  const { records } = useAppSelector(recordsSelector);
  const { id: accountId, roles } = useAppSelector(authSelector);

  const navigate = useNavigate();

  const breakPoint = useBreakPoint();

  const clanRecords = records.get(id) || [];

  const isAdminForClan =
    roles.find((role) => role.clan_id === id)?.role_id !== rolesValuesMap.user;

  const filteredRecords = isAdminForClan
    ? clanRecords
    : clanRecords.filter((record) => record.publisherId === accountId);

  const handleNavigateClan = () => {
    navigate(`/records/department/${id}`);
  };

  const recordList = filteredRecords.slice(0, 2);

  return (
    <div
      className="flex flex-col py-[16px] px-[24px] gap-[12px] bg-dark rounded-[10px] cursor-pointer hover:bg-light"
      onClick={handleNavigateClan}
    >
      <div className="flex justify-between">
        <span className="tpg-b1">{clan}</span>
        <span className="tpg-b2 text-tpg_base">{place}</span>
      </div>
      {filteredRecords.length ? (
        <span className="tpg-c1 text-bright_product">{`${filteredRecords.length} в записи`}</span>
      ) : (
        <EmtyScreen text="Нет ни одной записи" className="!h-[250px]" />
      )}
      <div
        className={cn('grid grid-cols-2 gap-[4px] w-full', {
          '!grid-cols-1': breakPoint === 'mobile' || breakPoint === 'tablet',
        })}
      >
        {recordList.map(
          ({
            id: recordId,
            previewSrc,
            publisherName,
            publisherId,
            tags,
            startedAt,
            endedAt,
            description,
          }) => (
            <Card
              type="records"
              key={recordId}
              cardId={recordId}
              tags={tags}
              clanId={id}
              publisherName={publisherName}
              publisherId={publisherId}
              src={previewSrc}
              startedAt={startedAt}
              endedAt={endedAt}
              description={description}
            />
          )
        )}
      </div>
    </div>
  );
};
