import { FC, useState } from 'react';

import { Button, Modal, TextInput } from 'components/ui';

interface DeleteClanModalProps {
  clanName: string;
  onClose: () => void;
  onRemove: () => void;
}

export const DeleteClanModal: FC<DeleteClanModalProps> = ({
  clanName,
  onClose,
  onRemove,
}) => {
  const [checkName, setCheckName] = useState('');

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] max-w-[530px] w-[530px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <div className="flex flex-col gap-[12px] items-center">
          <span className="tpg-h4 text-center">
            Вы уверены, что хотите удалить подразделение?
          </span>
          <span className="tpg-b2 text-tpg_base">
            Вы можете потерять ценные данные.
          </span>
          <span className="tpg-b2 text-tpg_base text-center">
            Введите имя удаляемого подразделения, чтобы подтвердить удаление.
          </span>
          <TextInput
            placeholder="Введите значение"
            inputClassName="bg-ultrablack"
            onChange={(value) => setCheckName(value)}
            value={checkName}
          />
        </div>
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={onClose}
            title="Нет, я передумал"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button
            disabled={checkName !== clanName}
            onClick={onRemove}
            title="Да, я хочу удалить"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  );
};
