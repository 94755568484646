import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint, useRoles } from 'hooks';
import { authSelector } from 'store/slices/auth/selectors';
import { roomActions } from 'store/slices/room';
import {
  deleteRoomIngressThunk,
  deleteRoomThunk,
  getClanRoomsThunk,
} from 'store/slices/room/actions';
import { roomSelector } from 'store/slices/room/selectors';

import { Loader } from 'components/ui/Loader';

import { StreamController } from './StreamController';

interface IStreamLaunchProps {
  roomPrefix: string;
}

export const StreamLaunch: FC<IStreamLaunchProps> = ({ roomPrefix }) => {
  const { roles } = useAppSelector(authSelector);

  const { selectedRoom, pending } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const { state: clanHost }: { state?: string } = useLocation();

  const breakPoint = useBreakPoint();

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const clanId = useMemo(() => {
    if (!clanHost) return getClanHost();

    return clanHost;
  }, [clanHost, roles]);

  const roomName = `${roomPrefix}_stream`;

  useEffect(() => {
    clanId && dispatch(getClanRoomsThunk(clanId));

    return () => {
      if (clanId) {
        dispatch(
          deleteRoomThunk({
            clanId,
            roomName,
            params: { save_recording: false },
          })
        );

        dispatch(
          deleteRoomIngressThunk({
            clanId,
            roomName,
          })
        );

        dispatch(roomActions.setMethod(null));
      }
    };
  }, [clanId, roomName]);

  const handleFinish = () => {
    dispatch(roomActions.setMethod(null));
  };

  if (pending)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  return (
    <LiveKitRoom
      className={cn('flex flex-col gap-[16px] w-[630px] m-auto', {
        'w-[330px]': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
      token={selectedRoom?.token}
      serverUrl={process.env.REACT_APP_LIVEKIT_API}
    >
      <StreamController onFinish={handleFinish} />
    </LiveKitRoom>
  );
};
