import { errorMessages } from '../constants/messages';
import { IListAuditLogResponse } from '../interfaces/audit';
import { IClanPossibleValue, IListAccountsParams } from '../interfaces/player';
import axios from '../services/axios';

import { requestHandler } from './utils/requestHandler';

export const getAuditLogs = requestHandler(
  (params?: IListAccountsParams) =>
    axios.post<IListAuditLogResponse>('/audit', params),
  { defaultErrorMessage: errorMessages.GET_AUDIT_LOGS }
);

export const getPossibleAccountsAndClans = requestHandler(
  () => axios.get<IClanPossibleValue[]>('/possible-values'),
  { defaultErrorMessage: errorMessages.GET_POSSIBLE_VALUES }
);
