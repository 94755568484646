import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { IEntity, ISelectOption } from 'interfaces';

import STable, { STableFetchParams } from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';

import { getAuditLogs, getPossibleAccountsAndClans } from '../../api/audit';
import {
  auditTitleMap,
  getLogTypeKeys,
  IAuditLog,
} from '../../interfaces/audit';
import { IClanPossibleValue } from '../../interfaces/player';
import { convertFilterValueFromSelectOption } from '../../utils';
import {
  getClanCell,
  getRowDescription,
  getUsersCell,
} from '../../utils/audit';

const getHeadCellsData = (clans: ISelectOption[], users: ISelectOption[]) => [
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Событие',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Дата и время',
    sortableProperty: 'date',
    dateFilter: true,
  },
  {
    id: 'log_type',
    numeric: false,
    disablePadding: false,
    label: 'Тип события',
    possibleValues: Object.values(auditTitleMap),
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Подразделение',
    possibleValues: clans.map((el) => el.label),
  },
  {
    id: 'users',
    numeric: false,
    disablePadding: false,
    label: 'Пользователи',
    possibleValues: users.map((el) => el.label),
  },
];

export const AuditTable = () => {
  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);
  const [possibleClans, setPossibleClans] = useState<ISelectOption[]>([]);
  const [possibleUsers, setPossibleUsers] = useState<ISelectOption[]>([]);

  const fetchRows = useMemo(
    () => async (params: STableFetchParams) => {
      const fetchFilterValues: IFilterValue[] = selectedFilters.map(
        (selectedFilter) => {
          let convertedValues: string[];

          switch (selectedFilter.id) {
            case 'log_type':
              convertedValues = getLogTypeKeys(selectedFilter.values);

              break;
            case 'clan':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                possibleClans
              );

              break;
            case 'users':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                possibleUsers
              );

              break;

            default:
              convertedValues = selectedFilter.values;
          }

          return {
            id: selectedFilter.id,
            values: convertedValues,
          };
        }
      );

      const response = await getAuditLogs({
        offset: params.start,
        limit: params.size,
        filters: fetchFilterValues,
      }).then((r) => r.data);

      return {
        rows: response?.result || [],
        total: response?.total || 0,
      };
    },
    [selectedFilters]
  );

  useEffect(() => {
    const fetchPossibleValues = async () => {
      try {
        const response = await getPossibleAccountsAndClans();

        if (response.data) {
          const clans =
            response.data.find((el: IClanPossibleValue) => el.id === 'clan')
              ?.values || [];

          setPossibleClans(
            clans.map((el: IEntity) => ({
              label: el.name,
              value: el.id,
            }))
          );

          const users =
            response.data.find((el: IClanPossibleValue) => el.id === 'users')
              ?.values || [];

          setPossibleUsers(
            users.map((el: IEntity) => ({
              label: el.name,
              value: el.id,
            }))
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPossibleValues();
  }, []);

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(possibleClans, possibleUsers),
    [possibleClans, possibleUsers]
  );

  return (
    <div className="relative">
      <STable<IAuditLog, string>
        headCells={ENTITIES_HEAD_CELLS}
        rowIdExtractor={(row) => row?.id}
        fetchRows={fetchRows}
        classNames={{
          root: 'group !h-[calc(100vh-148px)]',
          row: 'group hover:!bg-ultrablack',
          cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
          body: '[&>tr>td]:h-[93px]',
        }}
        onFiltersChange={setSelectedFilters}
        selectedFilters={selectedFilters}
        isPagination
        renderRowCells={(row, props) => {
          const formatedDate = format(new Date(row.date), 'dd.MM.yyyy HH:mm');

          const descriptionContent = DOMPurify.sanitize(
            formatedDate + ' ' + getRowDescription(row),
            { ADD_ATTR: ['target'] }
          );

          return (
            <>
              <STableCell
                {...props}
                key={`description-${row.id}`}
                propName="payload"
                cellType="text"
                dangerouslySetInnerHTML={{ __html: descriptionContent }}
              />
              <STableCell
                {...props}
                key={`date-${row.id}`}
                propName="date"
                cellType="text"
              >
                {formatedDate}
              </STableCell>
              <STableCell
                {...props}
                key={`log_type-${row.id}`}
                propName="log_type"
                cellType="text"
              >
                {auditTitleMap[row.log_type]}
              </STableCell>
              <STableCell
                {...props}
                key={`clan-${row.id}`}
                propName="payload"
                cellType="text"
              >
                {getClanCell(row)}
              </STableCell>
              <STableCell
                {...props}
                key={`log_type-${row.id}`}
                propName="payload"
                cellType="text"
              >
                {getUsersCell(row)}
              </STableCell>
            </>
          );
        }}
      />
    </div>
  );
};
