import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { store } from 'store';

import ErrorBoundary from 'components/ErrorBoundary';
import NotificationContainer from 'components/NotificationContainer';
import { UpdateNotification } from 'components/UpdateNotification';

import { App } from './App';

import './styles/main.scss';
import './styles/common.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </BrowserRouter>
      <UpdateNotification />
      <NotificationContainer />
    </Provider>
  </ErrorBoundary>
);
