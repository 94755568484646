import axios from 'services/axios';

import { errorMessages, successMessages } from '../constants/messages';
import {
  IRecordsByClanIdParams,
  IRecordsByClansParams,
} from '../interfaces/record';

import { IRecordingStatusResponse, IRecordParams } from './types/record';
import { requestHandler } from './utils/requestHandler';

export const getRecordsByClanId = requestHandler(
  ({ clanId, from, to }: IRecordsByClanIdParams) =>
    axios.get(`/clans/${clanId}/recordings`, {
      params: { from, to },
    })
);

export const getRecordsByClans = requestHandler(
  ({ clanIds, from, to }: IRecordsByClansParams) =>
    axios.post(`/clans/recordings`, { clan_ids: clanIds, from, to })
);

export const getRecord = requestHandler((recordId: string) =>
  axios.get(`/recordings/${recordId}`)
);

export const deleteRecord = requestHandler(
  (recordId: string) => axios.delete(`/recordings/${recordId}`),
  {
    defaultErrorMessage: errorMessages.DELETE_RECORD,
    defaultSuccessMessage: successMessages.DELETE_RECORD,
  }
);

export const startRecording = requestHandler(
  ({ clanId, roomName }: IRecordParams) =>
    axios.post<IRecordingStatusResponse>(`/recordings/start`, {
      clan_id: clanId,
      room_name: roomName,
    }),
  {
    defaultErrorMessage: errorMessages.START_RECORDING_FAILED,
  }
);

export const terminateRecording = requestHandler(
  ({ clanId, roomName }: IRecordParams) =>
    axios.post(`/recordings/terminate`, {
      clan_id: clanId,
      room_name: roomName,
    }),
  {
    defaultErrorMessage: errorMessages.TERMINATE_RECORDING_FAILED,
    defaultSuccessMessage: successMessages.TERMINATE_RECORDING,
  }
);
