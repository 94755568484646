import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';

import { FilterSection } from 'components/FilterSection';
import { DateTag } from 'components/FilterSection/DateTag';

import { LP_INTERVAL_MS } from '../../constants/profile';
import { recordsSelector } from '../../store/slices/records';
import { getRecordsByClansThunk } from '../../store/slices/records/actions';

import { ClansStreamList } from './ClansStreamList';

export const ClansScreen = () => {
  const [streamDate, setStreamDate] = useState<(string | null)[]>([]);

  const { clans } = useAppSelector(clansSelector);
  const { pending: recordsPending } = useAppSelector(recordsSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  useEffect(() => {
    if (clans.length) {
      dispatch(
        getRecordsByClansThunk({ clanIds: clans.map((clan) => clan.id) })
      );

      const recordsInterval = setInterval(() => {
        if (!recordsPending) {
          dispatch(
            getRecordsByClansThunk({ clanIds: clans.map((clan) => clan.id) })
          );
        }
      }, LP_INTERVAL_MS);

      return () => {
        clearInterval(recordsInterval);
      };
    }
  }, [clans]);

  return (
    <div className="flex flex-col gap-[16px]">
      {!!streamDate.length && (
        <div className="relative">
          <FilterSection
            streamDate={streamDate}
            setStreamDate={setStreamDate}
          />
          <div className="absolute top-0 right-[24px] flex flex-col gap-[8px]">
            {!!streamDate.length && (
              <DateTag date={streamDate} setDate={setStreamDate} />
            )}
          </div>
        </div>
      )}
      {!!clans.length &&
        clans.map(({ id, name, work_area }) => (
          <ClansStreamList clan={name} id={id} place={work_area} key={id} />
        ))}
    </div>
  );
};
