import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { banAccount, getAccount } from 'api/account';
import { warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { IOption } from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';
import { mockHistory } from 'mock';
import { authSelector, clansSelector } from 'store';
import {
  getClansThunk,
  updateClanMemberThunk,
} from 'store/slices/clan/actions';
import { registerThunk } from 'store/slices/player/actions';
import { rolesValuesMap } from 'types/player';

import { AdminTable } from 'components/AdminTable';
import { DeleteModal } from 'components/DeleteModal';
import { HistoryInfo } from 'components/HistoryInfo';
import { Loader } from 'components/ui/Loader';
import { getUserFormFieldsError, notify } from 'utils';

import { AccessList } from './AccessList';
import { BasicInfo } from './BasicInfo';

interface PlayerAdminPanelScreenProps {
  isNewPlayer?: boolean;
}

export type TPlayerInfo = Omit<
  IPlayer,
  'access' | 'status' | 'clan_id' | 'clan_name'
> & {
  status: IOption;
  originClan: IOption;
};

const initPlayerInfo: TPlayerInfo = {
  id: 'new',
  banned: false,
  name: '',
  role: EUserRole.USER,
  clans: [],
  roles: [],
  status: {
    value: 'Не авторизован',
    label: 'Не авторизован',
  },
  launchedStreams: 0,
  external_id: '',
  email: '',
  password: '',
  originClan: {
    value: '',
    label: '',
  },
  meta: {
    notifications: [],
  },
};

export const PlayerAdminPanelScreen: FC<PlayerAdminPanelScreenProps> = ({
  isNewPlayer,
}) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { roles, role, id: accountId } = useAppSelector(authSelector);
  const { clans: allClans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();
  const [existedPlayer, setExistedPlayer] = useState<IPlayer>();
  const [player, setPlayer] = useState<TPlayerInfo>(initPlayerInfo);
  const [pending, setPending] = useState(false);
  const [addedClans, setAddedClans] = useState<IClanPlayer[]>([]);

  const [initialPlayer, setInitialPlayer] =
    useState<TPlayerInfo>(initPlayerInfo);

  const [accesses, setAccesses] = useState<IClanPlayer[]>([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [basicInfoFieldsErrorMessage, setBasicInfoFieldsErrorMessage] =
    useState({
      name: '',
      email: '',
      password: '',
    });

  const clans = useMemo(
    () =>
      player.roles
        .filter(
          (role) => !addedClans.some((clan) => clan.clanId === role.clan_id)
        )
        .map((role) => ({
          clanId: role.clan_id,
          clanName: role.clan_name,
          roleId: role.role_id,
          roleName: role.role_name,
        })),
    [player]
  );

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  useEffect(() => {
    const fetchPlayer = async () => {
      setPending(true);

      try {
        if (id) {
          const response = await getAccount(id);
          setExistedPlayer(response.data);
        } else {
          setExistedPlayer(undefined);
        }
      } finally {
        setPending(false);
      }
    };

    fetchPlayer();
  }, [id]);

  useEffect(() => {
    if (isNewPlayer || !existedPlayer) {
      setPlayer(initPlayerInfo);

      return;
    }

    const nativeClanInitialValue = allClans.flatMap((clan) =>
      clan.id === existedPlayer.clan_id
        ? {
            label: clan.name,
            value: clan.id,
          }
        : []
    )[0];

    const initialExistedPlayer = {
      id: existedPlayer.id,
      banned: existedPlayer.banned,
      role: existedPlayer.role,
      clans: existedPlayer.clans,
      name: existedPlayer.name,
      roles: existedPlayer?.roles,
      status: {
        value: existedPlayer?.status,
        label: existedPlayer?.status,
      },
      launchedStreams: existedPlayer?.launchedStreams,
      external_id: existedPlayer?.email,
      email: existedPlayer?.email,
      password: existedPlayer?.password,
      originClan: nativeClanInitialValue || {
        label: '',
        value: '',
      },
      meta: existedPlayer?.meta,
    };

    setPlayer(initialExistedPlayer);
    setInitialPlayer(initialExistedPlayer);
  }, [id, existedPlayer, isNewPlayer]);

  const handleChangePlayerInfo = (info: TPlayerInfo) => {
    setPlayer(info);
  };

  const handleAddAccess = (value: IClanPlayer) => {
    setAddedClans((prev) => [...prev, value]);
    setAccesses((prev) => [...prev, value]);
  };

  const handleRemoveAccess = (id: string) => {
    setAddedClans((prev) => prev.filter((access) => access.clanId !== id));
    setAccesses((prev) => prev.filter((access) => access.clanId !== id));
  };

  const handleUpdateAccess = (value: IClanPlayer) => {
    setAccesses((prev) =>
      prev.map((access) =>
        access.clanId === value.clanId ? { ...access, ...value } : access
      )
    );
  };

  const handleUpdatePlayer = async () => {
    const { id, password, name, email, originClan } = player;

    if (isNewPlayer) {
      if (password && name && email) {
        if (!accesses.length) {
          notify.warning(warningMessages.ADD_NEW_PLAYER);

          return;
        }

        const error = getUserFormFieldsError({ password, name, email });

        if (error.email.length || error.name.length || error.password.length) {
          setBasicInfoFieldsErrorMessage(error);

          return;
        }

        const data = await dispatch(
          registerThunk({
            password,
            password2: password,
            name,
            email,
            accesses,
          })
        );

        if (data.type.includes('fulfilled')) {
          setBasicInfoFieldsErrorMessage({
            name: '',
            email: '',
            password: '',
          });

          navigate(-1);
        }
      } else {
        notify.warning(warningMessages.PROVIDE_BASIC_INFO);
      }
    } else {
      if (
        initialPlayer?.password === password &&
        initialPlayer?.email !== email
      ) {
        notify.warning(warningMessages.UPDATE_LOGIN, {
          autoClose: 3000,
        });

        return;
      }

      const error = getUserFormFieldsError({ password, name, email });

      if (error.email.length || error.name.length || error.password.length) {
        setBasicInfoFieldsErrorMessage(error);

        return;
      }

      const data = await dispatch(
        updateClanMemberThunk({
          id,
          name: initialPlayer.name !== name ? name : undefined,
          password: initialPlayer.password !== password ? password : undefined,
          external_id: initialPlayer.external_id !== email ? email : undefined,
          originClan:
            initialPlayer.originClan.value !== originClan.value
              ? originClan
              : undefined,
        })
      );

      if (data.type.includes('fulfilled')) {
        setBasicInfoFieldsErrorMessage({
          name: '',
          email: '',
          password: '',
        });
      }
    }
  };

  const handleRemovePlayer = async () => {
    const { error } = await banAccount(String(player.id));

    if (!error) {
      setOpenDeleteModal(false);
    }
  };

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForPlayer =
    !!highPriorityRoles.filter((role) =>
      clans.some((clan) => role.clan_id === clan.clanId)
    ).length || accountId === rolesValuesMap.root;

  const isAccessEditing =
    (isAdminForPlayer && !(accountId === rolesValuesMap.root)) ||
    role === EUserRole.ROOT;

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenModal = () => {
    setOpenDeleteModal(true);
  };

  const breakPoint = useBreakPoint();

  if (!player && !isNewPlayer) {
    return <span>Данные не загрузились</span>;
  }

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  const onSave =
    (!player.banned &&
      (isAdminForPlayer || accountId === rolesValuesMap.root)) ||
    isNewPlayer
      ? handleUpdatePlayer
      : undefined;

  const onRemove =
    id !== rolesValuesMap.root &&
    (accountId === rolesValuesMap.root || isAccessEditing) &&
    !player.banned
      ? handleOpenModal
      : undefined;

  return (
    <>
      <AdminTable
        title={
          isNewPlayer
            ? 'Новый пользователь'
            : `Пользователь ${player?.name} ${
                player.banned ? '(заблокирован)' : ''
              }`
        }
        buttonTitle="Удалить пользователя"
        listTitle={
          !(breakPoint === 'mobile' || breakPoint === 'tablet')
            ? 'Список доступов'
            : ''
        }
        historyTitle="История пользователя"
        isShowFooterController={true}
        isNewData={isNewPlayer}
        info={
          <BasicInfo
            playerInfo={player}
            initialPlayerInfo={initialPlayer}
            onChange={handleChangePlayerInfo}
            onSave={handleUpdatePlayer}
            isAccessEditing={isAccessEditing}
            isNewPlayer={isNewPlayer}
            fieldErrors={basicInfoFieldsErrorMessage}
          />
        }
        history={<HistoryInfo history={mockHistory} />}
        list={
          <AccessList
            isNewPlayer={isNewPlayer}
            onAddAccess={handleAddAccess}
            onRemoveAccess={handleRemoveAccess}
            onUpdateAccess={handleUpdateAccess}
            accountId={String(player.id)}
            isBanned={player.banned}
            roles={roles}
            clans={isNewPlayer ? accesses : addedClans.concat(clans)}
            isAccessEditing={isAccessEditing}
            highPriorityRoles={highPriorityRoles}
          />
        }
        onSave={isNewPlayer ? onSave : undefined}
        onRemove={onRemove}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить пользователя?"
          onClose={handleCloseModal}
          onRemove={handleRemovePlayer}
        />
      )}
    </>
  );
};
