import { errorMessages, successMessages } from 'constants/messages';
import {
  IPlayer,
  IPlayerClanResponse,
  IPlayerResponse,
} from 'interfaces/player';
import { IListAccountsParams, IListAccountsResponse } from 'interfaces/player';
import axios from 'services/axios';

import { IAccountRoleThunkData, IAccountThunkData } from './types/account';
import { requestHandler } from './utils/requestHandler';

export const getAccount = requestHandler(
  (accountId: string) => axios.get<IPlayer>(`/accounts/${accountId}`),
  { defaultErrorMessage: errorMessages.GET_ACCOUNT }
);

export const getAccountMe = requestHandler(
  () => axios.get<IPlayerResponse>('/accounts/me'),
  { defaultErrorMessage: errorMessages.GET_ACCOUNT }
);

export const getAccounts = requestHandler(
  (params?: IListAccountsParams) =>
    axios.post<IListAccountsResponse>('/accounts', params),
  { defaultErrorMessage: errorMessages.GET_ACCOUNTS }
);

export const getAccountsClans = requestHandler(
  () => axios.get<IPlayerClanResponse>('/accounts/clans'),
  { defaultErrorMessage: errorMessages.GET_ACCOUNTS }
);

export const updateAccount = requestHandler(
  ({
    id,
    name,
    originClanId,
    originClanName,
    metadata,
  }: Omit<IAccountThunkData, 'clanId'>) =>
    axios.patch(`/accounts/${id}`, {
      name,
      clan_id: originClanId,
      clan_name: originClanName,
      metadata,
    }),
  {
    defaultErrorMessage: errorMessages.UPDATE_PLAYER,
    defaultSuccessMessage: successMessages.UPDATE_PLAYER,
  }
);

export const updateAccountRole = requestHandler(
  ({ role_id, account_id, clan_id }: IAccountRoleThunkData) =>
    axios.put(`/accounts/${account_id}/roles`, {
      role_id,
      clan_id,
    }),
  {
    defaultErrorMessage: errorMessages.UPDATE_ROLE,
    defaultSuccessMessage: successMessages.UPDATE_ROLE,
  }
);

export const banAccount = requestHandler(
  (id: string) => axios.post('/ban', { account_id: id }),
  {
    defaultErrorMessage: errorMessages.DELETE_ACCOUNT,
    defaultSuccessMessage: successMessages.DELETE_PLAYER,
  }
);
