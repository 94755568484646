import { createSlice } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { IPlayer, IPlayerClan } from 'interfaces/player';
import { rolesReverseMap, rolesTranslateMap } from 'types/player';

import { filteredDataBySearchValue } from 'utils';
import { getHighestRole } from 'utils/getHighestRole';

import { getAccountsThunk, getAllPlayersClansThunk } from './actions';

interface IConvertedPlayerData {
  name: string;
  banned: string;
  external_id: string;
  roles: string;
  // computer: string;
  clan_name: string;
}

interface IPlayerState {
  allPlayerClans: IPlayerClan[];
  list: IPlayer[];
  filteredList: IPlayer[];
  convertedPlayersData: IConvertedPlayerData[];
  pending: boolean;
  error: string | null;
  total: number;
}

const initialState: IPlayerState = {
  allPlayerClans: [],
  list: [],
  filteredList: [],
  convertedPlayersData: [],
  pending: false,
  error: null,
  total: 0,
};

const playerSlice = createSlice({
  name: reducersNames.PLAYER,
  initialState,
  reducers: {
    setQuerySearch(state, { payload }) {
      const filteredConvertedPlayerData = filteredDataBySearchValue(
        state.convertedPlayersData,
        payload
      );

      const filteredPlayer = state.list?.filter((player) =>
        filteredConvertedPlayerData?.some(
          (data) => player.external_id === data.external_id
        )
      );

      if (filteredPlayer?.length) {
        state.filteredList = filteredPlayer;
      } else {
        if (payload) {
          state.filteredList = [];

          return;
        }

        state.filteredList = state.list;
      }
    },
    clearState(state) {
      state.list = [];
      state.filteredList = [];
      state.convertedPlayersData = [];
      state.pending = false;
      state.error = null;
      state.total = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccountsThunk.fulfilled, (state, { payload, meta }) => {
        const { accounts, total } = payload;

        const convertedPlayersData = accounts.map(
          ({ name, banned, external_id, roles, clan_name }) => ({
            name,
            external_id,
            banned: banned ? 'Забанен' : 'Активный',
            roles: roles
              .map((role) => rolesTranslateMap[rolesReverseMap[role.role_id]])
              .join(' ,'),
            clan_name: clan_name || 'Не выбрано',
          })
        );

        const convertedList = accounts.map((value) => ({
          ...value,
          role: getHighestRole(value.roles),
          status: '',
          access: [],
          clans: [],
          password: '',
        }));

        state.convertedPlayersData = convertedPlayersData;
        state.list = convertedList;
        state.filteredList = convertedList;
        state.total = total;

        state.error = null;
        state.pending = false;
      })
      .addCase(getAccountsThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAccountsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        getAllPlayersClansThunk.fulfilled,
        (state, { payload: { clans } }) => {
          state.allPlayerClans = clans;
          state.pending = false;
        }
      );
  },
});

export const { actions: playerActions, reducer: playerReducer } = playerSlice;

export * from './selectors';
