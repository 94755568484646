import { FC, MouseEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { format, intervalToDuration } from 'date-fns';
import {
  useAppDispatch,
  useAppSelector,
  useBreakPoint,
  useClickOutside,
} from 'hooks';
import { ITag } from 'interfaces';
// import { ReactComponent as EllipsisIcon } from 'images/newIcons/ellipsis.svg';
import { authSelector } from 'store';
import { rolesValuesMap } from 'types/player';

import { TagsSection } from 'components/TagsSection';
import { formatTime } from 'utils';

import { deleteRecordThunk } from '../../store/slices/records/actions';

type CardProps = {
  type: 'records' | 'stream';
  cardId: string;
  clanId: string;
  src?: string;
  className?: string;
  host?: string;
  tags?: string[];
  publisherName?: string;
  publisherId?: string;
  startedAt?: string;
  endedAt?: string;
  description?: string;
};

export const Card: FC<CardProps> = ({
  cardId,
  src,
  tags,
  type,
  className,
  clanId,
  publisherName,
  publisherId,
  startedAt,
  endedAt,
  description,
}) => {
  const [isOpenControllerModal, setOpenControllerModal] = useState(false);
  const [isShowButtons, setShowButtons] = useState(false);

  const { roles, id: accountId } = useAppSelector(authSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const breakPoint = useBreakPoint();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowButtons(false)
  );

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForClan = !!highPriorityRoles.find(
    (role) => role.clan_id === clanId
  );

  const isAccessToEdit =
    accountId === rolesValuesMap.root ||
    publisherId === accountId ||
    (isAdminForClan &&
      publisherId !== rolesValuesMap.root &&
      accountId !== rolesValuesMap.root);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    navigate(`/${type}/${cardId}`, {
      state: { clanId },
    });
  };

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    setShowButtons(true);
  };

  const handleDelete = async (e: MouseEvent) => {
    e.stopPropagation();
    setShowButtons(false);

    await dispatch(deleteRecordThunk(cardId));
  };

  const handleOpenModal = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenControllerModal(true);
  };

  const handleSaveRecord = () => {
    setOpenControllerModal(false);
  };

  const handleResume = () => {
    setOpenControllerModal(false);
  };

  const duration =
    startedAt &&
    endedAt &&
    intervalToDuration({ start: new Date(startedAt), end: new Date(endedAt) });

  const status =
    type === 'stream' ? (breakPoint !== 'mobile' ? 'В эфире' : '') : 'Запись';

  const cardTags: ITag[] = tags?.length
    ? tags.map((tag) => ({
        id: tag,
        title: tag,
      }))
    : [];

  return (
    <>
      <div
        className={cn(
          'relative aspect-[2] cursor-pointer border-solid border-tpg_light border-[1px] rounded-[10px] hover:[&>div]:after:opacity-0 [&>div]:after:transition-opacity',
          className
        )}
        ref={containerRef}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      >
        <div
          className="w-full h-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-full 
        after:h-full after:rounded-[10px] after:bg-[linear-gradient(180deg,_#161824,_#17192538,_#161824)]"
        >
          {src && (
            <img
              className="rounded-[10px] w-full h-full object-cover"
              src={src}
              alt=""
              onError={(e) => e.currentTarget.classList.add('hidden')}
            />
          )}
        </div>
        <div
          className={cn(
            'flex flex-row gap-[8px] items-center absolute tpg-c2 top-[10px] left-[32px] pointer-events-none',
            {
              '!left-[10px]':
                breakPoint === 'mobile' || breakPoint === 'tablet',
              'max-w-[180px]': breakPoint === 'mobile',
              'max-w-[140px]': breakPoint === 'mobile',
            }
          )}
        >
          {!(breakPoint === 'mobile' || breakPoint === 'tablet') && (
            <span
              className={cn(
                "relative before:content-[''] before:absolute before:top-[50%] before:left-[-15px] before:w-[8px] before:h-[8px] before:rounded-[50%] before:translate-y-[-50%]",
                {
                  ['before:bg-error text-error']: type === 'stream',
                  ['before:bg-tpg_base text-tpg_base']: type === 'records',
                }
              )}
            >
              {status}
            </span>
          )}
          {publisherName && (
            <div className="flex px-[12px] py-[5px] bg-ultrablack rounded-[5px]">
              <span className="tpg-c2">{publisherName}</span>
            </div>
          )}
        </div>
        <div
          className={cn(
            'absolute flex flex-row items-end gap-[4px] tpg-b2 top-[12px] right-[16px] pointer-events-none',
            {
              'flex-col':
                breakPoint === 'mobile' ||
                breakPoint === 'tablet' ||
                breakPoint === 'tablet-landscape',
            }
          )}
        >
          {duration && (
            <span className="tpg-c1">
              {duration ? formatTime(duration) : '00:00:00'}
            </span>
          )}
          {startedAt && (
            <span className="text-tpg_base tpg-c1">
              {format(new Date(startedAt), 'dd.MM.yyyy')}
            </span>
          )}
        </div>
        {!!cardTags?.length && (
          <div className="w-full px-[8px] absolute bottom-[8px]  pointer-events-none">
            <TagsSection
              tags={cardTags}
              textButton="ещё"
              classNameTag="!bg-ultrablack !px-[12px] !py-[5px]"
            />
          </div>
        )}
        {/* temporarily hidden until the back is ready */}
        {/* {type === 'record' && isAccessToEdit && (
          <div
            className="absolute right-[16px] bottom-[10px] group cursor-pointer flex items-center justify-center transition"
            onClick={handleOpenModal}
          >
            <EllipsisIcon className="group-hover:[&>circle]:fill-bright_product [&>circle]:transition" />
          </div>
        )} */}
        {isShowButtons && (
          <div className="absolute z-[1] left-[50%] top-[50%] py-[6px] translate-x-[100%] translate-y-[-50%] max-w-[133px] right-0 flex flex-col rounded-[10px] bg-ultrablack border border-solid border-tpg_light">
            <span
              className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
              onClick={(e) => handleDelete(e)}
            >
              Удалить
            </span>
          </div>
        )}
      </div>
    </>
  );
};
