import React, { CSSProperties, memo, ReactNode } from 'react';
import cn from 'classnames';
import { ReactComponent as DefaultFilterIcon } from 'images/newIcons/filter.svg';
import { ReactComponent as DefaultFilterActiveIcon } from 'images/newIcons/filter-active.svg';

import { ElementDirectionUnion } from '../../../constants/routes';

import styles from './Filters.module.scss';

interface BaseFilterProps {
  isOpen: boolean;
  isActive: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dropdownContent: ReactNode;
  iconSize?: number;
  dropdownDirection?: ElementDirectionUnion;
  containerStyle?: CSSProperties;
  activeIcon?: React.ReactNode;
  icon?: React.ReactNode;
}

const BaseFilter = ({
  isOpen,
  isActive,
  setIsOpen,
  dropdownContent,
  iconSize = 16,
  containerStyle,
  icon,
  activeIcon,
}: BaseFilterProps) => {
  const filterActiveIcon = activeIcon ? (
    activeIcon
  ) : (
    <DefaultFilterActiveIcon width={iconSize} height={iconSize} />
  );

  const filterIcon = icon ? (
    icon
  ) : (
    <DefaultFilterIcon width={iconSize} height={iconSize} />
  );

  return (
    <div
      className={`cursor-pointer ${styles['filters-icon']}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen || isActive ? filterActiveIcon : filterIcon}
      <div
        className={cn(
          `absolute flex flex-col ${styles['filters-container']}`,
          isOpen ? '' : 'hidden'
        )}
        style={containerStyle}
        onClick={(e) => e.stopPropagation()}
      >
        {dropdownContent}
      </div>
    </div>
  );
};

export default memo(BaseFilter);
