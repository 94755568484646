import {
  CSSProperties,
  FC,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import { Link } from '../Link';
import Spinner from '../Spinner';

import './style.scss';

type SyncClickPropsType = {
  onClick: (event: MouseEvent) => void;
  isAsync?: undefined | false;
};
type AsyncOnClickPropsType = {
  onClick: () => Promise<void>;
  isAsync: true;
};
type SmartButtonProps = {
  className?: string;
  textClassName?: string;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  style?: CSSProperties;
} & (SyncClickPropsType | AsyncOnClickPropsType);

const Button: FC<PropsWithChildren<SmartButtonProps>> = ({
  className,
  textClassName,
  children,
  title,
  isLoading,
  onClick,
  disabled,
  isAsync,
  type = 'button',
  style,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(isLoading);

  useEffect(() => {
    setIsButtonLoading(isLoading);
  }, [isLoading]);

  const handleClick = (event: MouseEvent) => {
    if (isLoading || disabled) return;

    if (!isAsync) {
      onClick(event);
    } else if (isAsync) {
      setIsButtonLoading(true);
      onClick().finally(() => setIsButtonLoading(false));
    }
  };

  return (
    <Link
      className={cn(
        'smart-button',
        { 'smart-button__disabled': disabled },
        className
      )}
      title={title}
      textClassName={cn('smart-button__text', textClassName || 'tpg-c2')}
      onClick={handleClick}
      type={type}
      style={style}
    >
      <div className="flex flex-row gap-[4px] justify-center items-center">
        {isButtonLoading && <Spinner size={20} />}
        {!title && children}
      </div>
    </Link>
  );
};

export default Button;
