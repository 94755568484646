import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector, getAccountMeThunk, updateMfaRequiredThunk } from 'store';
import { rolesValuesMap } from 'types/player';

import { Switch } from 'components/ui';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

interface ProfileModalProps {
  onClose: () => void;
}

export const ProfileModal: FC<ProfileModalProps> = ({ onClose }) => {
  const { username, email, id, mfaRequired, roles } =
    useAppSelector(authSelector);

  const [isMfaRequired, setMfaRequired] = useState(!!mfaRequired);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccountMeThunk());
  }, []);

  useEffect(() => {
    setMfaRequired(!!mfaRequired);
  }, [mfaRequired]);

  const isAdmin = roles.find((role) => role.role_id !== rolesValuesMap.user);

  const handleTwoFactorChange = () => {
    setMfaRequired(!isMfaRequired);
  };

  const handleButtonClick = () => {
    if (id) {
      dispatch(
        updateMfaRequiredThunk({
          account_id: id,
          mfa_required: isMfaRequired,
        })
      );

      onClose();
    }
  };

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] w-[460px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <span className="tpg-h4 text-center">Настройки профиля</span>
        <div className="flex flex-col gap-[12px]">
          <div className="h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
            <span className="tpg-c1 pointer-events-none">{username}</span>
          </div>
          <div className="h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
            <span className="tpg-c1 pointer-events-none">{email}</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="tpg-c1">Двухфакторная аутентификация</span>
            <Switch
              disabled={mfaRequired && !isAdmin}
              checked={isMfaRequired}
              onChange={handleTwoFactorChange}
            />
          </div>
        </div>
        <Button
          onClick={handleButtonClick}
          title="Сохранить"
          className="w-full h-[40px]"
        />
      </div>
    </Modal>
  );
};
