import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  recordAdapter,
  recordsAdapter,
  recordsByClansAdapter,
} from 'api/helpers/recordsAdapter';
import {
  deleteRecord,
  getRecord,
  getRecordsByClanId,
  getRecordsByClans,
} from 'api/records';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  IRecord,
  IRecordsByClanIdParams,
  IRecordsByClansParams,
} from 'interfaces/record';

export const getRecordsByClanIdThunk = createAsyncThunk<
  { clanId: string; records: IRecord[] },
  IRecordsByClanIdParams
>(
  `${reducersNames.RECORDS}/${asyncActionsNames.GET_CLAN_RECORDS}`,
  async (request, thunkAPI) => {
    const { clanId } = request;

    const { data, error } = await getRecordsByClanId(request);

    if (data) {
      return {
        clanId,
        records: recordsAdapter(data),
      };
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getRecordsByClansThunk = createAsyncThunk<
  Record<string, IRecord[]>,
  IRecordsByClansParams
>(
  `${reducersNames.RECORDS}/${asyncActionsNames.GET_RECORDS_BY_CLANS}`,
  async (request, thunkAPI) => {
    const { data, error } = await getRecordsByClans(request);

    if (data) {
      return recordsByClansAdapter(data);
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getRecordThunk = createAsyncThunk<IRecord, string>(
  `${reducersNames.RECORDS}/${asyncActionsNames.GET_RECORD}`,
  async (request, thunkAPI) => {
    const { data, error } = await getRecord(request);

    if (data) {
      return recordAdapter(data);
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const deleteRecordThunk = createAsyncThunk<string, string>(
  `${reducersNames.RECORDS}/${asyncActionsNames.DELETE_RECORD}`,
  async (recordId, thunkAPI) => {
    const { error } = await deleteRecord(recordId);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    return recordId;
  }
);
