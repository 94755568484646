import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import { useAppDispatch } from 'hooks';
import { useStreamControl } from 'hooks/useStreamControl';
import { useStreamHeight } from 'hooks/useStreamHeight';
import { useStreamsDynamicGrid } from 'hooks/useStreamsDynamicGrid';
import { useWindowSize } from 'hooks/useWindowSize';
import { IRoomParams } from 'interfaces';
import { getAllLiveKitTokensThunk } from 'store/slices/room/actions';
import { useDebouncedCallback } from 'use-debounce';

import { EmtyScreen } from 'components/EmtyScreen';
import { MainRoomPanel } from 'components/MainRoomPanel';
import { Room } from 'components/Room';
import { Button } from 'components/ui';
import { convertRoomNameToParams, getWordDayForm } from 'utils';

interface PanelProps {
  isFullscreenOpen: boolean;
  onBack: () => void;
  rooms: IRoomParams[];
  clanId?: string;
}

const countClansAndRooms = (rooms: IRoomParams[]) => {
  const clanIds = rooms.map((room) => room.clanId);

  return {
    totalClans: Array.from(new Set(clanIds)).length,
    totalRooms: rooms.length,
  };
};

export const Panel: FC<PanelProps> = ({
  isFullscreenOpen,
  onBack,
  rooms,
  clanId: id,
}) => {
  const { height } = useWindowSize();

  const [mainRoom, setMainRoom] = useState<IRoomParams>();

  const [searchParams, setSearchParams] = useSearchParams();

  const mainRoomParams = searchParams.get('mainRoom');

  const dispatch = useAppDispatch();

  const unhiddenRooms = useMemo(
    () => rooms.filter((room) => !room.isHidden),
    [rooms]
  );

  const {
    visibleStreams,
    unloadedStreams,
    maxVisibleStreams,
    handleVisibleStreamsChange,
  } = useStreamControl(unhiddenRooms, isFullscreenOpen);

  const gridSize = useStreamsDynamicGrid(visibleStreams);

  const screenHeight = useMemo(
    () => (isFullscreenOpen ? height - PADDING : height - HEADER_HEIGHT),
    [isFullscreenOpen, height]
  );

  const streamHeight = useStreamHeight(visibleStreams, screenHeight);

  const setTokenForNewRooms = useDebouncedCallback(
    (filterdRooms: IRoomParams[]) => {
      const newRooms = filterdRooms?.filter((newRoom) => !newRoom.token);

      if (newRooms.length) {
        const params = newRooms.map((room) => {
          const { clan_id, room_name } = convertRoomNameToParams(
            room.livekitName
          );

          return {
            clan_id,
            room_name,
          };
        });

        if (params.length) {
          dispatch(getAllLiveKitTokensThunk(params));
        }
      }
    },
    100
  );

  useEffect(() => {
    if (!mainRoom && !mainRoomParams) {
      setTokenForNewRooms(visibleStreams);
    }
  }, [visibleStreams, mainRoom, mainRoomParams]);

  useEffect(() => {
    if (mainRoom?.livekitName) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        mainRoom: mainRoom.livekitName,
      });
    }
  }, [mainRoom, searchParams]);

  useEffect(() => {
    const room = unhiddenRooms.find(
      (room) => room.livekitName === (mainRoom?.livekitName || mainRoomParams)
    );

    if (!room && (mainRoom || mainRoomParams) && unhiddenRooms.length) {
      const filteredRoom = unhiddenRooms.filter(
        (room) => room.livekitName !== mainRoom?.livekitName
      );

      setMainRoom(filteredRoom[0]);
    }

    if (mainRoom && !mainRoom?.token) {
      const room = unhiddenRooms.find(
        (room) => room.livekitName === mainRoomParams
      );

      if (room?.token) {
        setMainRoom(room);
      }
    }

    if (mainRoomParams && !mainRoom && unhiddenRooms.length) {
      const room = unhiddenRooms.find(
        (room) => room.livekitName === mainRoomParams
      );

      setMainRoom(room);
    }

    if (unhiddenRooms.length === 1 && mainRoomParams) {
      searchParams.delete('mainRoom');

      setMainRoom(undefined);

      setSearchParams(Object.fromEntries(searchParams));
    }

    if (!unhiddenRooms.length && mainRoom) {
      searchParams.delete('mainRoom');

      setSearchParams(Object.fromEntries(searchParams));
    }

    if (!unhiddenRooms.length && !mainRoomParams) {
      setMainRoom(undefined);
    }
  }, [unhiddenRooms, mainRoom, mainRoomParams, searchParams]);

  const handleMainStreamSelect = (livekitName: string) => {
    if (livekitName) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        mainRoom: livekitName,
      });

      const room = unhiddenRooms.find(
        (room) => room.livekitName === livekitName
      );

      if (!room?.token && room?.clanId) {
        dispatch(
          getAllLiveKitTokensThunk([convertRoomNameToParams(room.livekitName)])
        );

        setMainRoom((prevRoom) =>
          prevRoom?.livekitName === livekitName ? undefined : room
        );

        return;
      }

      setMainRoom((prevRoom) =>
        prevRoom?.livekitName === livekitName ? undefined : room
      );
    } else {
      searchParams.delete('mainRoom');

      setSearchParams(Object.fromEntries(searchParams));

      setMainRoom(undefined);
    }
  };

  const clansAndRoomsCounter = countClansAndRooms(unloadedStreams);

  const streamWordForm = getWordDayForm(clansAndRoomsCounter.totalRooms, [
    'эфир',
    'эфира',
    'эфиров',
  ]);

  const maxVisibleStreamsWordForm = getWordDayForm(maxVisibleStreams, [
    'эфир',
    'эфира',
    'эфиров',
  ]);

  return (
    <div className="relative flex flex-col gap-[16px] w-[calc(100%+48px)] m-[-24px] p-[8px]">
      {isFullscreenOpen && (
        <div
          onClick={onBack}
          className="flex flex-row gap-[5px] absolute left-[50%] translate-x-[-50%] top-0 py-[6px] px-[16px] z-[1] bg-ultrablack/80 rounded-b-[5px]"
        >
          <span className="tpg-c2 text-bright_product">esc</span>
          <span className="tpg-c2">вернуть обратно</span>
        </div>
      )}
      {unhiddenRooms?.length ? (
        mainRoom ? (
          <MainRoomPanel
            isFullscreenOpen={isFullscreenOpen}
            mainRoom={mainRoom}
            rooms={unhiddenRooms}
            clanId={id}
            onSelectMainRoom={handleMainStreamSelect}
          />
        ) : (
          <>
            <div
              className="grid gap-[5px] w-full"
              style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}
            >
              {visibleStreams.map(
                ({
                  livekitName,
                  publisherName,
                  token,
                  clanId,
                  clanName,
                  roomName,
                  accountId,
                }) => (
                  <Room
                    key={livekitName}
                    accountId={accountId}
                    livekitName={livekitName}
                    roomName={roomName}
                    publisherName={publisherName}
                    token={token}
                    clanId={clanId || id}
                    clanName={clanName}
                    height={streamHeight}
                    isMain={!!mainRoom}
                    isShowSwitchButtonMain={visibleStreams.length > 1}
                    onSelectMainRoom={handleMainStreamSelect}
                  />
                )
              )}
            </div>
            {!!unloadedStreams.length && (
              <div className="flex flex-col items-center gap-[16px] py-[32px] text-center bg-ultrablack sticky z-[9]">
                <span className="tpg-b2 text-tpg_base">
                  {`Доступно ещё ${clansAndRoomsCounter.totalRooms} ${streamWordForm} из ${clansAndRoomsCounter.totalClans} подразделений`}
                </span>
                <Button
                  title={`Загрузить ${maxVisibleStreams} ${maxVisibleStreamsWordForm}`}
                  onClick={handleVisibleStreamsChange}
                  className="w-full max-w-[360px] h-[40px]"
                />
              </div>
            )}
          </>
        )
      ) : (
        <EmtyScreen
          isFullscreenOpen={isFullscreenOpen}
          text="Нет ни одного эфира"
        />
      )}
    </div>
  );
};
