import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppSelector, useBreakPoint } from 'hooks';

import { Button } from 'components/ui';

import { NotificationType } from '../../constants/profile';
import { lastNotificationsSelector } from '../../store';

export const UpdateNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const breakPoint = useBreakPoint();
  const notification = useAppSelector(lastNotificationsSelector);

  useEffect(() => {
    setIsOpen(Boolean(notification));
  }, [notification]);

  const handleReload = () => window.location.reload();
  const handleClose = () => setIsOpen(false);

  return notification ? (
    <div
      className={cn(
        'fixed bottom-[50px] transform transition-transform translate-y-0 duration-300 ease-in-out left-1/2 -translate-x-1/2 flex flex-col text-center py-[32px] px-[24px] gap-[16px] rounded-[10px] bg-ultrablack max-w-[410px] z-[100]',
        {
          '!min-w-[290px]': breakPoint === 'mobile',
          'translate-y-[40vh]': !isOpen,
        }
      )}
    >
      <div className="flex flex-col">
        <span className="tpg-b1">Внимание!</span>
        <span className="tpg-b2">{notification.info}</span>
      </div>
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none max-h-[30px]"
        onClick={handleClose}
      >
        <span className="text-2xl">&times;</span>
      </button>
      {notification.type === NotificationType.REFRESH && (
        <Button
          onClick={handleReload}
          title="Обновить"
          className="w-full py-[13px]"
        />
      )}
    </div>
  ) : null;
};
