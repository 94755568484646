import { createSlice } from '@reduxjs/toolkit';
import { ELStorageKeys } from 'constants/localStorage';
import { EUserRole } from 'constants/profile';
import { reducersNames } from 'constants/reducers';
import { IAuthState } from 'interfaces/store';

import { getToken } from 'utils';
import { getHighestRole } from 'utils/getHighestRole';
import { clearLStorageMany, getLStorage } from 'utils/helpers/localStorage';

import {
  checkMfaParamsThunk,
  getAccountMeThunk,
  loggedInThunk,
  loginThunk,
  updateMfaRequiredThunk,
} from './actions';

const initialState: IAuthState = {
  isLogged: !!getToken(),
  pending: false,
  error: null,
  role: null,
  roles: [],
  id: '',
  accessToken: getToken(),
  refreshToken: getLStorage(ELStorageKeys.refreshToken),
  username: '',
  email: '',
  redirectUrl: null,
  mfaState: null,
  mfaRequired: false,
  mfaEnabled: false,
  notifications: [],
};

const authSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {
    logout(state) {
      clearLStorageMany(...Object.values(ELStorageKeys));
      state.role = EUserRole.USER;
      state.accessToken = null;
      state.username = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, { payload: { redirect_url } }) => {
        if (redirect_url) {
          state.redirectUrl = redirect_url;
          window.location.replace(redirect_url);
        }

        state.pending = false;
      })
      .addCase(loginThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        loggedInThunk.fulfilled,
        (
          state,
          {
            payload: {
              token,
              user_name,
              account_id,
              mfa_state,
              mfa_required,
              user_email,
              mfa_enabled,
            },
          }
        ) => {
          state.accessToken = token;
          state.username = user_name;
          state.id = account_id;
          state.mfaState = mfa_state;
          state.mfaRequired = mfa_required;
          state.email = user_email;
          state.mfaEnabled = mfa_enabled;
          state.redirectUrl = null;
          state.isLogged = true;

          state.pending = false;
        }
      )
      .addCase(
        checkMfaParamsThunk.fulfilled,
        (
          state,
          { payload: { token, user_name, account_id, mfa_state, user_email } }
        ) => {
          state.accessToken = token;
          state.username = user_name;
          state.id = account_id;
          state.mfaState = mfa_state;
          state.mfaRequired = true;
          state.email = user_email;
          state.redirectUrl = null;
          state.isLogged = true;

          state.pending = false;
        }
      )
      .addCase(getAccountMeThunk.fulfilled, (state, { payload }) => {
        state.role = getHighestRole(payload.roles);
        state.roles = payload.roles;
        state.username = payload.name;
        state.id = payload.id;
        state.email = payload.email;
        state.mfaRequired = payload.mfa_required;
        state.pending = false;
        state.notifications = payload.meta?.notifications || [];

        if (!state.isLogged) state.isLogged = true;
      })
      .addCase(getAccountMeThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAccountMeThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(updateMfaRequiredThunk.fulfilled, (state, { payload }) => {
        state.mfaRequired = payload?.mfa_required;
        state.pending = false;
      })
      .addCase(updateMfaRequiredThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateMfaRequiredThunk.rejected, (state) => {
        state.pending = false;
      });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;

export * from './actions';
export * from './selectors';
