import { EUserRole } from './profile';
import { appRoutes } from './routes';

const baseNavs = [
  {
    link: appRoutes.STREAMS,
    title: 'Эфир',
  },
  {
    link: appRoutes.RECORDS,
    title: 'Записи',
  },
];

const adminMavs = [
  {
    link: appRoutes.ADMIN_DEPARTMENTS,
    title: 'Подразделения',
  },
  {
    link: appRoutes.ADMIN_USERS,
    title: 'Пользователи',
  },
];

export const navs = {
  [EUserRole.MODERATOR]: [...baseNavs],
  [EUserRole.USER]: [...baseNavs],
  [EUserRole.ADMIN]: [...baseNavs, ...adminMavs],
  [EUserRole.ROOT]: [
    ...baseNavs,
    ...adminMavs,
    {
      link: appRoutes.AUDIT,
      title: 'Аудит',
    },
  ],
};
