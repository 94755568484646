import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { LP_INTERVAL_MS } from 'constants/profile';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { ITag } from 'interfaces';
import { authSelector, streamActions, streamSelector } from 'store';
import { recordsSelector } from 'store/slices/records';
import { getRecordsByClanIdThunk } from 'store/slices/records/actions';
import { rolesValuesMap } from 'types/player';

import { Card } from 'components/Card';
import { EmtyScreen } from 'components/EmtyScreen';
import { DateTag } from 'components/FilterSection/DateTag';
import { TagsSection } from 'components/TagsSection';
import { Tag } from 'components/ui/Tag';
import { filterRecordBySelectedTags, mergeUniqueRecordTags } from 'utils';

interface IRecordSectionProps {
  clanId: string;
}

export const RecordSection: FC<IRecordSectionProps> = ({ clanId }) => {
  const [streamDate, setStreamDate] = useState<(string | null)[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  const { searchQuery } = useAppSelector(streamSelector);

  const { records } = useAppSelector(recordsSelector);

  const { id: accountId, roles } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  const clanRecords = records.get(clanId) || [];

  const isAdminForClan =
    roles.find((role) => role.clan_id === clanId)?.role_id !==
    rolesValuesMap.user;

  const filteredRecords = isAdminForClan
    ? clanRecords
    : clanRecords.filter((record) => record.publisherId === accountId);

  const tags: ITag[] = mergeUniqueRecordTags(filteredRecords).map((tag) => ({
    id: tag,
    title: tag,
  }));

  const filteredRecordBySelectedTags = filterRecordBySelectedTags(
    filteredRecords,
    selectedTags
  );

  const recordList = filteredRecordBySelectedTags.length
    ? filteredRecordBySelectedTags
    : filteredRecords;

  useEffect(() => {
    const getRecords = () => {
      const [from, to] = streamDate;

      const params: Record<string, string> = {
        ...(from && { from: dayjs(from).toISOString() }),
        ...(to && { to: dayjs(to).toISOString() }),
      };

      dispatch(getRecordsByClanIdThunk({ clanId, ...params }));
    };

    getRecords();

    const recordsInterval = setInterval(getRecords, LP_INTERVAL_MS);

    return () => clearInterval(recordsInterval);
  }, [clanId, streamDate]);

  // const handleDatePicker = (dates: TDateRange) => {
  //   const formatedDates = new Set(
  //     dates.map((date) => date && format(date, 'dd.MM.yyyy'))
  //   );

  //   setStreamDate(Array.from(formatedDates));
  // };

  if (!clanRecords.length) {
    return <EmtyScreen text="Нет ни одной записи" />;
  }

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-row justify-between items-center">
        {/* TODO: return when date filter data is ready */}
        {/* <DatePickerDropdown
          value={!streamDate.length ? [null, null] : undefined}
          onValueChange={handleDatePicker}
          className="mt-5 right-0"
          iconClassName={cn(
            'cursor-pointer',
            streamDate.length && '[&>path]:fill-bright_product'
          )}
        /> */}
      </div>
      {!!tags?.length && (
        <TagsSection
          tags={tags}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
        />
      )}
      {(!!streamDate.length || searchQuery) && (
        <div className="flex flex-row gap-[8px]">
          {searchQuery && (
            <Tag
              label="Поисковый запрос"
              onRemove={() => dispatch(streamActions.clearQuerySearch())}
              closeIcon
            />
          )}
          {!!streamDate.length && (
            <DateTag date={streamDate} setDate={setStreamDate} />
          )}
        </div>
      )}
      <div
        className={cn('grid grid-cols-2 gap-[16px] w-full', {
          '!grid-cols-1': breakPoint === 'mobile' || breakPoint === 'tablet',
        })}
      >
        {recordList.map(
          ({
            id,
            previewSrc,
            tags,
            publisherName,
            publisherId,
            startedAt,
            endedAt,
            description,
            clanId,
          }) => (
            <Card
              type="records"
              key={id}
              cardId={id}
              src={previewSrc}
              tags={tags}
              clanId={clanId}
              publisherName={publisherName}
              publisherId={publisherId}
              startedAt={startedAt}
              endedAt={endedAt}
              description={description}
            />
          )
        )}
      </div>
    </div>
  );
};
