import {
  ELogType,
  IAuditLog,
  IBaseAuditLogPayload,
  IClanAuditLogPayload,
  IClanMemberAuditLogPayload,
  IRoomAuditLogPayload,
  IUserAuditLogPayload,
} from '../interfaces/audit';
import { rolesReverseMap, rolesTranslateMap } from '../types/player';

export const getAccountLink = (payload: IUserAuditLogPayload): string =>
  `<a href="/admin-panel/users/${payload.accountId}" target="_blank" >${payload.accountName}</a>`;

export const getActorLink = (
  payload: IBaseAuditLogPayload,
  actorId: string
): string =>
  `<a href="/admin-panel/users/${actorId}" target="_blank" >${payload.actorName}</a>`;

export const getRoomLink = (payload: IRoomAuditLogPayload): string =>
  `<a  href="/rooms/${payload.roomId}" target="_blank">${payload.roomId}</a>`;

export const getClanLink = (payload: IClanAuditLogPayload): string =>
  `<a href="/admin-panel/departments/${payload.clanId}" target="_blank" >${payload.clanName}</a>`;

export const getRowDescription = (log: IAuditLog): string => {
  switch (log.log_type) {
    case ELogType.UserRegister: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} зарегистрировал пользователя ${getAccountLink(payload)}`;
    }

    case ELogType.UserLogin: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} совершил вход в систему`;
    }

    case ELogType.UserChangePassword: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} обновил пароль для пользователя ${getAccountLink(payload)}`;
    }

    case ELogType.UserChangeEmail: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} обновил логин для пользователя ${getAccountLink(payload)}`;
    }

    case ELogType.UserBan: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} забанил пользователя ${getAccountLink(payload)}`;
    }

    case ELogType.UserUpdate: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} обновил пользователя ${getAccountLink(payload)}`;
    }

    case ELogType.ClanCreate: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} создал подразделение ${getClanLink(payload)}`;
    }

    case ELogType.ClanUpdate: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} обновил подразделение ${getClanLink(payload)}`;
    }

    case ELogType.ClanDelete: {
      const payload = log.payload as IClanAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} удалил подразделение ${getClanLink(payload)}`;
    }

    case ELogType.MfaSelfSet: {
      const payload = log.payload as IBaseAuditLogPayload;

      return `Пользователь ${payload.actorName} установил двухфакторную аутентификацию`;
    }

    case ELogType.MfaRequiredSet: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} установил двухфакторную аутентификацию пользователю ${getAccountLink(
        payload
      )}`;
    }

    case ELogType.MfaRequiredReset: {
      const payload = log.payload as IUserAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} сбросил двухфакторную аутентификацию пользователю ${getAccountLink(
        payload
      )}`;
    }

    case ELogType.ClanMemberSet: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} добавил в подразделение ${getClanLink(
        payload
      )} пользователя ${getAccountLink(payload)} с ролью ${getRoleTitleById(
        payload.roleId
      )}`;
    }

    case ELogType.ClanMemberRemove: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} удалил пользователя ${getAccountLink(
        payload
      )} из подразделения ${getClanLink(payload)}`;
    }

    case ELogType.RoleAssignmentAdd: {
      const payload = log.payload as IClanMemberAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} назначил роль ${getRoleTitleById(
        payload.roleId
      )} пользователю ${getAccountLink(payload)}в подразделении ${getClanLink(
        payload
      )}`;
    }

    case ELogType.RoomCreate: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} запустил эфир ${getRoomLink(payload)} в подразделении ${getClanLink(
        payload
      )}`;
    }

    case ELogType.RoomDelete: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} завершил эфир ${getRoomLink(payload)} в подразделении ${getClanLink(
        payload
      )}`;
    }

    case ELogType.RecordingStart: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} начал запись эфира ${getRoomLink(
        payload
      )} в подразделении ${getClanLink(payload)}`;
    }

    case ELogType.RecordingEnd: {
      const payload = log.payload as IRoomAuditLogPayload;

      return `Пользователь ${getActorLink(
        payload,
        log.user_id
      )} завершил запись эфира ${getRoomLink(
        payload
      )} в подразделении ${getClanLink(payload)}`;
    }

    default: {
      return 'Ошибка при получении описания';
    }
  }
};

export const getClanCell = (log: IAuditLog): string => {
  if (
    [
      ELogType.ClanCreate,
      ELogType.ClanCreate,
      ELogType.ClanUpdate,
      ELogType.ClanDelete,
      ELogType.ClanMemberSet,
      ELogType.ClanMemberRemove,
      ELogType.RoleAssignmentAdd,
      ELogType.RoomCreate,
      ELogType.RoomDelete,
    ].includes(log.log_type)
  ) {
    const payload = log.payload as IClanAuditLogPayload;

    return payload.clanName;
  }

  return log.payload.actorClanName;
};

export const getUsersCell = (log: IAuditLog): string => {
  if (
    [
      ELogType.UserRegister,
      ELogType.UserUpdate,
      ELogType.UserChangePassword,
      ELogType.UserChangeEmail,
      ELogType.UserBan,
      ELogType.MfaRequiredSet,
      ELogType.MfaRequiredReset,
      ELogType.ClanMemberSet,
      ELogType.ClanMemberRemove,
      ELogType.RoleAssignmentAdd,
    ].includes(log.log_type)
  ) {
    const payload = log.payload as IUserAuditLogPayload;

    return `${payload.actorName}, ${payload.accountName}`;
  }

  return `${log.payload.actorName}`;
};

const getRoleTitleById = (roleIdStr: string) => {
  const roleKey = rolesReverseMap[roleIdStr] || '';

  return rolesTranslateMap[roleKey];
};
