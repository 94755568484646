export interface IAuditLog {
  id: string;
  date: Date;
  user_id: string;
  log_type: ELogType;
  payload: AuditLogPayload;
}

export type AuditLogPayload =
  | IUserAuditLogPayload
  | IClanAuditLogPayload
  | IClanMemberAuditLogPayload
  | IRoomAuditLogPayload
  | IGenericAuditLogPayload;

export interface IBaseAuditLogPayload {
  actorName: string;
  actorClanId: string;
  actorClanName: string;
}

export interface IUserAuditLogPayload extends IBaseAuditLogPayload {
  accountId: string;
  accountName: string;
}

export interface IClanAuditLogPayload extends IBaseAuditLogPayload {
  clanId: string;
  clanName: string;
}

export interface IClanMemberAuditLogPayload extends IBaseAuditLogPayload {
  clanId: string;
  accountId: string;
  roleId: string;
  clanName: string;
  accountName: string;
}

export interface IRoomAuditLogPayload extends IBaseAuditLogPayload {
  roomId: string;
  egressOn: boolean;
  clanId: string;
  clanName: string;
}

export interface IGenericAuditLogPayload {
  [key: string]: any;
}

export interface IListAuditLogResponse {
  result: IAuditLog[];
  total: number;
}

export enum ELogType {
  UserLogin = 'user_login',
  UserRegister = 'user_register',
  UserChangePassword = 'user_change_password',
  UserChangeEmail = 'user_change_email',
  UserBan = 'user_ban',
  UserUpdate = 'user_update',
  ClanCreate = 'clan_create',
  ClanUpdate = 'clan_update',
  ClanDelete = 'clan_delete',
  ClanMemberSet = 'clan_member_set',
  ClanMemberRemove = 'clan_member_remove',
  RoomCreate = 'room_create',
  RoomDelete = 'room_delete',
  RecordingStart = 'recording_start',
  RecordingEnd = 'recording_end',
  RoleAssignmentAdd = 'role_assignment_add',
  MfaRequiredSet = 'mfa_required_set',
  MfaRequiredReset = 'mfa_required_reset',
  MfaSelfSet = 'mfa_set_self',
}

export const auditTitleMap: Record<ELogType, string> = {
  [ELogType.UserLogin]: 'Вход',
  [ELogType.UserRegister]: 'Регистрация',
  [ELogType.UserChangePassword]: 'Изменение пароля',
  [ELogType.UserChangeEmail]: 'Изменение логина',
  [ELogType.UserBan]: 'Бан пользователя',
  [ELogType.UserUpdate]: 'Обновление пользователя',
  [ELogType.ClanCreate]: 'Создание подразделения',
  [ELogType.ClanUpdate]: 'Обновление подразделения',
  [ELogType.ClanDelete]: 'Удаление подразделения',
  [ELogType.ClanMemberSet]: 'Добавление участника',
  [ELogType.ClanMemberRemove]: 'Удаление участника',
  [ELogType.RoomCreate]: 'Создание эфира',
  [ELogType.RoomDelete]: 'Удаление эфира',
  [ELogType.RecordingStart]: 'Вкл. записи эфира',
  [ELogType.RecordingEnd]: 'Выкл. записи эфира',
  [ELogType.RoleAssignmentAdd]: 'Добавление роли',
  [ELogType.MfaRequiredSet]: 'Установка 2FA',
  [ELogType.MfaRequiredReset]: 'Сброс 2FA',
  [ELogType.MfaSelfSet]: 'Установка 2FA себе',
};

export const getLogTypeKeys = (titles: string[]): ELogType[] =>
  titles
    .map((title) => {
      for (const [key, value] of Object.entries(auditTitleMap)) {
        if (value === title) {
          return key as ELogType;
        }
      }

      return null;
    })
    .filter((key) => key !== null) as ELogType[];
