import { useEffect, useState } from 'react';
import { IRoomParams } from 'interfaces';

import { useBreakPoint } from './useBreakPoint';
import { useWindowSize } from './useWindowSize';

const GAP = 5;

export const useStreamHeight = (rooms: IRoomParams[], screenHeight: number) => {
  const [streamHeight, setStreamHeight] = useState(0);

  const breakPoint = useBreakPoint();
  const { height } = useWindowSize();

  useEffect(() => {
    const count =
      ((breakPoint === 'mobile' || breakPoint === 'tablet') && 1) ||
      (rooms.length <= 2 && 1) ||
      (rooms.length >= 3 && rooms.length <= 6 && 2) ||
      (rooms.length >= 7 && rooms.length <= 12 && 3) ||
      (rooms.length >= 13 && rooms.length <= 20 && 4) ||
      5;

    if (height) {
      const value = screenHeight / count;

      if (value) {
        const difference = value - GAP;

        setStreamHeight(difference);
      }
    }
  }, [height, rooms, breakPoint, screenHeight]);

  return streamHeight;
};
