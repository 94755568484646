import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ReactComponent as PinIcon } from 'images/newIcons/pin.svg';
import { ReactComponent as PinActiveIcon } from 'images/newIcons/pinActive.svg';
import { IRoom, ITreeRooms } from 'interfaces';
import { roomActions } from 'store/slices/room';

import { allRoomsHideController, roomHideController } from 'utils';

import { Dropdown } from './Dropdown';
import {
  updateHiddenClansParams,
  updateHiddenRoomsParams,
  updateSearchParams,
} from './utils';

interface ListClansProps {
  activeClans: ITreeRooms[];
  inactiveClans: ITreeRooms[];
  treeRooms: ITreeRooms[];
  isAllActiveStreamsHidden: boolean;
  isAllInactiveStreamsHidden: boolean;
  onAllActiveStreamsHide: (value: boolean) => void;
  onAllInactiveStreamsHide: (value: boolean) => void;
}

export const ListClans: FC<ListClansProps> = ({
  activeClans,
  inactiveClans,
  treeRooms,
  isAllActiveStreamsHidden,
  isAllInactiveStreamsHidden,
  onAllActiveStreamsHide,
  onAllInactiveStreamsHide,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const handleAllActiveStreamsClick = (isAllHidden?: boolean) => {
    const updatedTreeRooms = allRoomsHideController(
      treeRooms,
      activeClans,
      isAllHidden || !isAllActiveStreamsHidden
    );

    const showClans = updatedTreeRooms.flatMap(({ isHidden, clanId }) =>
      !isHidden ? { clanId } : []
    );

    const newSearchParams = updateSearchParams(
      searchParams,
      showClans,
      isAllHidden ? false : isAllActiveStreamsHidden,
      isAllInactiveStreamsHidden
    );

    setSearchParams(newSearchParams);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    onAllActiveStreamsHide(!isAllActiveStreamsHidden);
  };

  const handleAllInactiveStreamsClick = (isAllHidden?: boolean) => {
    const updatedTreeRooms = allRoomsHideController(
      treeRooms,
      inactiveClans,
      isAllHidden || !isAllInactiveStreamsHidden
    );

    const showClans = updatedTreeRooms.flatMap(({ isHidden, clanId }) =>
      !isHidden ? { clanId } : []
    );

    const newSearchParams = updateSearchParams(
      searchParams,
      showClans,
      isAllHidden ? false : isAllInactiveStreamsHidden,
      isAllActiveStreamsHidden
    );

    setSearchParams(newSearchParams);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    onAllInactiveStreamsHide(!isAllInactiveStreamsHidden);
  };

  const handleRoomHide = (livekitName: string, clanId: string) => {
    const updatedTreeRooms = roomHideController(treeRooms, clanId, livekitName);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    const newParams = updateHiddenRoomsParams(
      treeRooms,
      searchParams,
      updatedTreeRooms
    );

    setSearchParams(newParams);
  };

  const handleClanHide = (clanId?: string, isHidden?: boolean) => {
    if (clanId) {
      let currentRooms: ITreeRooms[] = treeRooms;

      const selectedClan = currentRooms.find((room) => room.clanId === clanId);

      if (selectedClan?.rooms.length) {
        selectedClan?.rooms.forEach(({ livekitName }) => {
          const updatedTreeRooms = roomHideController(
            currentRooms,
            clanId,
            livekitName,
            isHidden || !selectedClan.isHidden
          );

          if (updatedTreeRooms) {
            currentRooms = updatedTreeRooms;

            dispatch(roomActions.updateTreeRooms(currentRooms));
          }
        });
      } else {
        const updatedRooms = currentRooms.map((room) =>
          room.clanId === selectedClan?.clanId
            ? { ...room, isHidden: isHidden || !room.isHidden }
            : room
        );

        currentRooms = updatedRooms;

        dispatch(roomActions.updateTreeRooms(updatedRooms));
      }

      const newParams = updateHiddenClansParams(
        searchParams,
        clanId,
        treeRooms,
        currentRooms,
        isHidden
      );

      setSearchParams(newParams);
    }
  };

  const handlePinClick = (clanId: string, flag: boolean) => {
    const updatedTreeRooms = treeRooms.map((rooms) =>
      rooms.clanId === clanId
        ? {
            ...rooms,
            isPinned: flag,
          }
        : rooms
    );

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    const pinnedParams = updatedTreeRooms.flatMap(
      ({ isPinned, clanId, rooms }) => {
        const result = isPinned ? [{ clanId }] : [];

        const pinnedRooms = rooms.flatMap((room) => {
          if (room.isPinned) {
            return room.publisherName;
          }

          return [];
        });

        return pinnedRooms.length
          ? [...result, { clanId, rooms: pinnedRooms }]
          : result;
      }
    );

    if (pinnedParams.length) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        pinned: JSON.stringify(pinnedParams),
      });
    } else {
      searchParams.delete('pinned');

      setSearchParams(searchParams);
    }
  };

  const selectedInactiveClans = inactiveClans.filter(
    ({ isHidden }) => !isHidden
  );

  const selectedActiveClans = activeClans.filter(({ rooms }) =>
    rooms.some(({ isHidden }) => !isHidden)
  );

  const unhiddenRooms = (rooms: IRoom[]) =>
    rooms.filter(({ isHidden }) => !isHidden);

  return (
    <div className="flex flex-col gap-[6px]">
      {!!inactiveClans.length && (
        <Dropdown
          label="Неактивны"
          sublabel={`(${
            selectedInactiveClans.length === inactiveClans.length
              ? `${selectedInactiveClans.length} выбрано`
              : `${selectedInactiveClans.length} из ${inactiveClans.length} выбрано`
          })`}
          action={
            <span
              className="tpg-c2 text-bright_product cursor-pointer"
              onClick={() => handleAllInactiveStreamsClick(true)}
            >
              Сбросить
            </span>
          }
          labelClassName="!text-tpg_title"
          isActive={!isAllInactiveStreamsHidden}
          onParentClick={() => handleAllInactiveStreamsClick()}
        >
          {inactiveClans.map(
            ({ rooms, clanName, clanId, isHidden, isPinned }) => (
              <div
                key={clanId}
                className="flex flex-row justify-between pl-[16px]"
              >
                <Dropdown
                  id={clanId}
                  rooms={rooms}
                  label={clanName}
                  isActive={!isHidden}
                  onChildClick={handleRoomHide}
                  onParentClick={handleClanHide}
                  action={
                    <div className="flex flex-row gap-[6px] cursor-pointer w-max justify-end pr-[6px]">
                      {!!unhiddenRooms(rooms).length &&
                        unhiddenRooms(rooms).length !== rooms.length && (
                          <span
                            className="tpg-c2 text-bright_product"
                            onClick={() => handleClanHide(clanId, true)}
                          >
                            Сбросить
                          </span>
                        )}
                      <span className="tpg-c2 text-tpg_title">
                        {unhiddenRooms(rooms).length &&
                        unhiddenRooms(rooms).length !== rooms.length
                          ? `${unhiddenRooms(rooms).length} из ${rooms.length}`
                          : rooms.length}
                      </span>
                    </div>
                  }
                />
                {!isPinned && (
                  <PinIcon
                    className="cursor-pointer"
                    onClick={() => handlePinClick(clanId, true)}
                  />
                )}
                {isPinned && (
                  <PinActiveIcon
                    className="cursor-pointer"
                    onClick={() => handlePinClick(clanId, false)}
                  />
                )}
              </div>
            )
          )}
        </Dropdown>
      )}
      {!!activeClans.length && (
        <Dropdown
          label="Ведут эфир"
          sublabel={`(${
            selectedActiveClans.length === activeClans.length
              ? `${selectedActiveClans.length} выбрано`
              : `${selectedActiveClans.length} из ${activeClans.length} выбрано`
          })`}
          action={
            <span
              className="tpg-c2 text-bright_product cursor-pointer"
              onClick={() => handleAllActiveStreamsClick(true)}
            >
              Сбросить
            </span>
          }
          labelClassName="!text-tpg_title"
          isActive={!isAllActiveStreamsHidden}
          onParentClick={() => handleAllActiveStreamsClick()}
          isOpen={true}
        >
          {activeClans.map(
            ({ rooms, clanName, clanId, isHidden, isPinned }) => (
              <div
                key={clanId}
                className="flex flex-row justify-between pl-[16px]"
              >
                <Dropdown
                  id={clanId}
                  rooms={rooms}
                  label={clanName}
                  isActive={!isHidden}
                  onChildClick={handleRoomHide}
                  onParentClick={handleClanHide}
                  action={
                    <div className="flex flex-row gap-[6px] cursor-pointer w-max pr-[6px]">
                      {!!unhiddenRooms(rooms).length &&
                        unhiddenRooms(rooms).length !== rooms.length && (
                          <span
                            className="tpg-c2 text-bright_product"
                            onClick={() => handleClanHide(clanId, true)}
                          >
                            Сбросить
                          </span>
                        )}
                      <span className="tpg-c2 text-tpg_title">
                        {unhiddenRooms(rooms).length &&
                        unhiddenRooms(rooms).length !== rooms.length
                          ? `${unhiddenRooms(rooms).length} из ${rooms.length}`
                          : rooms.length}
                      </span>
                    </div>
                  }
                />
                {!isPinned && (
                  <PinIcon
                    className="cursor-pointer"
                    onClick={() => handlePinClick(clanId, true)}
                  />
                )}
                {isPinned && (
                  <PinActiveIcon
                    className="cursor-pointer"
                    onClick={() => handlePinClick(clanId, false)}
                  />
                )}
              </div>
            )
          )}
        </Dropdown>
      )}
    </div>
  );
};
