import { createSlice } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { IRecordsSlice, TSliceWithPromiseFlags } from 'interfaces';
import { IRecord } from 'interfaces/record';

import {
  deleteRecordThunk,
  getRecordsByClanIdThunk,
  getRecordsByClansThunk,
  getRecordThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IRecordsSlice> = {
  records: new Map<string, IRecord[]>(),
  selectedRecord: null,
  error: '',
  pending: false,
};

const recordsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getRecordsByClanIdThunk.fulfilled,
        (state, { payload: { clanId, records } }) => {
          state.records.set(clanId, records);
        }
      )
      .addCase(getRecordsByClanIdThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getRecordsByClanIdThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(getRecordsByClansThunk.fulfilled, (state, { payload }) => {
        Object.keys(payload).forEach((clanId) =>
          state.records.set(clanId, payload[clanId])
        );

        state.pending = false;
      })
      .addCase(getRecordsByClansThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getRecordsByClansThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(getRecordThunk.fulfilled, (state, { payload }) => {
        state.selectedRecord = payload;
        state.pending = false;
      })
      .addCase(getRecordThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getRecordThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        deleteRecordThunk.fulfilled,
        (state, { payload: recordingId }) => {
          state.records.forEach((records, clanId) => {
            if (records.find((record) => record.id === recordingId)) {
              state.records.set(
                clanId,
                records.filter((record) => record.id !== recordingId)
              );

              return;
            }
          });

          state.error = null;
          state.pending = false;
        }
      )
      .addCase(deleteRecordThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(deleteRecordThunk.rejected, (state) => {
        state.pending = false;
      });
  },
});

export const { actions: recordsActions, reducer: recordsReducer } =
  recordsSlice;

export * from './selectors';
