import { FC } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';

import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

interface SupportModalProps {
  onClose: () => void;
}

export const SupportModal: FC<SupportModalProps> = ({ onClose }) => {
  const breakPoint = useBreakPoint();

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  const handleTelegramClick = () => {
    window.open('https://t.me/astrashelp', '_blank');
  };

  const handleInstructionsClick = () => {
    window.open('https://t.me/+rUWkHlCtlQw0MTNi', '_blank');
  };

  const handleFormClick = () => {
    window.open(
      'https://forms.yandex.ru/cloud/67a4d54702848f7da1234826/',
      '_blank'
    );
  };

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] w-[650px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <span className="tpg-h4 text-center">Техподдержка 24/7</span>
        <div className="flex flex-col gap-[16px]">
          <div
            className={cn('flex flex-row gap-[4px]', {
              '!flex-col': isMobile,
            })}
          >
            <div className="flex flex-col py-[30px] bg-light w-full items-center">
              <span className="tpg-h3 text-bright_product">18310-3681</span>
              <span className="tpg-b2 text-tpg_base">номер режимной связи</span>
            </div>
            <div className="flex flex-col py-[30px] bg-light w-full items-center">
              <span className="tpg-h3 text-bright_product">@astrashelp</span>
              <span className="tpg-b2 text-tpg_base">тг аккаунт</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[12px]">
          <Button
            onClick={handleTelegramClick}
            title="Написать в телеграме"
            className="w-full h-[40px]"
          />
          <div
            className={cn('flex flex-row gap-[4px] w-full', {
              '!flex-col': isMobile,
            })}
          >
            <Button
              onClick={handleInstructionsClick}
              title="тг канал с инструкциями"
              className="w-full h-[40px] hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
            <Button
              onClick={handleFormClick}
              title="пройти опрос качества "
              className="w-full h-[40px] hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
